.nominations {
  background: $white;
  padding: 26px 0 clamp(50px, 6vw, 100px);

  &__title {
    font-size: clamp(20px, 4vw, 30px);
    @include medium;
    font-weight: 700;
    display: flex;
    line-height: 1.2;
    color: $black;
    padding: 23px 0;

    @include sm {
      justify-content: center; } }

  &__content {
    margin-bottom: 40px;
    @include tablet {
      padding: 0 8px 0 0; }
    @include md {
      margin-bottom: 30px; } }
  &__item {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: -9999px;
      right: -9999px;
      top: 0;
      border-top: 1px solid #EAEAEA; }

    @include sm {
      width: 100%;
      max-width: 100%; } }

  &__btn {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;

    .btn {
      display: inline-flex;
      margin: 0 auto;
      padding: 24px 31.5px;
      @include medium;

      &:hover {
        background: $black;
        color: $white; }

      @include tablet {
        padding: 15px 30px;
        font-size: 16px;
        margin: 0 auto; }

      @include md;

      @include sm {
        max-width: 100%;
        padding: 12px 30px; }
      @include xs {
        width: 100%;
        padding: 12px 20px; }
      &_red {
        color: #ff2407; } }
    @include tablet {
      margin-top: 0; }
    @include md {
      margin-top: 30px!important; } } }

.nomination {
  position: relative;
  padding: 23px 0;
  display: flex;

  @include tablet {
    padding: 20px 0; }
  @include md {
    padding: 18px 0; }

  &__text {
    font-size: clamp(20px, 4vw, 30px);
    @include medium; }
		font-weight: 700;
		display: flex;
		line-height: 1.2;
		color: #535353; }

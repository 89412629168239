
.s-prices {
  font-family: Sora, sans-serif;

  .section__title {
    line-height: .9;
    font-size: clamp(44px,8vw,80px);
    text-transform: uppercase;
    font-weight: 900;
    text-align: left;
    margin-bottom: 50px; } }
.prices-swiper {

  .swiper-slide {
    width: 374px;
    @include md {
      width: 320px; }
    @include sm {
      width: 280px; } }
  .swiper-container {
    @include laptop {
      overflow: visible; } }
  .swiper-pagination-bullet {
    background: #212121 !important;
    &-active {
      background: var(--accentColor) !important; } } }

.sponsors {
  padding: clamp(50px, 5vw, 100px) 0;

  .section__title {
    padding: 0 clamp(20px, 5vw, 50px);
    margin-bottom: 28px;

    @include sm {
      padding: 0; } }

  &__description {
    padding: 0 clamp(20px, 5vw, 50px);
    color: $grayD;
    margin: 0 auto 62px;

    p {
      line-height: 1.5;

      &:not(:last-child) {
        margin-bottom: 18px;
        @include sm {
          margin-bottom: 5px; } } } }

  &__offer {
    font-size: 18px;
    color: $white;

    @include sm {
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center; }

    p {
      display: flex;
      gap: 20px;
      align-items: center;

      @include sm {
        flex-direction: column; } }

    a {
      @extend .btn;
      @extend .btn--border;
      font-size: 14px;
      min-height: 50px;

      display: inline-flex;
      min-width: 170px;

      @include sm {
        min-height: 70px;
        width: 100%;
        max-width: 330px;
        font-size: 18px; } } } }

.sponsors-block {
  padding: 26px 30px;
  position: relative;
  border: 1px solid $gray;
  border-radius: 20px;

  &:not(:first-child) {
    margin-top: 20px; }

  @include sm {
    padding: 18px 20px; }

  &__title {
    font-size: clamp(16px, 5vw, 20px);
    font-weight: 700;
    margin-bottom: 20px;
    color: #999;
    line-height: 1; }

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -20px;
    @include sm {
      margin: 0 -10px; } }

  &__item {
    text-align: center;
    width: 100%;

    @include tablet {
      display: flex;
      justify-content: center; }

    @include sm {
      max-width: 230px; }

    & > a {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: opacity 0.3s;
      padding: 20px;
      @include sm {
        padding: 10px; }

      &:hover {
        opacity: 0.7; }

      picture {
        display: block; }

      img {
        display: block;
        max-width: 100%;
        transition: all ease 0.2s;
        transform: translateY(0);
        opacity: 1;
        height: auto; } }

    &--w-info {
      width: 100% !important;
      max-width: 100% !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      order: 1; } }
  &__content {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    font-size: 12px;
    line-height: 1.45;
    @include sm {
      font-size: 10px; }
    a {
      color: inherit;
      text-decoration: underline;
      &:hover {
        text-decoration: none; } }
    p:not(:last-child) {
      margin-bottom: 10px; } }
  &__subtitle {
    font-weight: 600;
    margin-bottom: 5px; }
  &.Platinum {
    .sponsors-block__item {
      width: 50%;
      max-width: 50%;
      @include sm {
        width: 100%;
        max-width: 100%; } } }

  &.Gold {
    .sponsors-block__item {
      width: 33.33%;
      max-width: 33.3%;
      @include xs {
        width: 50%;
        max-width: 50%; } } }

  &.Silver {
    .sponsors-block__item {
      width: 25%;
      max-width: 25%;

      @include xs {
        width: 33.3%;
        max-width: 33.3%; } } }

  &.MediaPartner,
  &.Partner,
  &.TechPartner,
  &.EntertainmentPartner {

    .sponsors-block__item {
      width: 20%;
      max-width: 20%;

      @include xs {
        width: 25%;
        max-width: 25%; } } }

  &_auto {
    .sponsors-block__list {
      justify-content: space-between; }

    .sponsors-block__item {
      width: auto;

      @include sm {
        width: 25%; }
      @include xs {
        width: 33.3%; }

      img {
        max-width: 100%; }

      &:last-child {
        margin: 0 auto 0 0; } } } }
